import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { postLogin, getPayments } from 'Redux/actions';
import { setAppLanguage } from 'Redux/actions_ui';
import { receiveApi, compareJson } from 'Helpers/helpers';
import loc from 'Components/languages';
import LoadingOverlay from 'Components/loading-overlay';
import ModalPassword from 'Pages/full-menu/components/ModalPassword.jsx';
import * as cons from 'Redux/constants';
import styles from './login.less';

class LoginLayout extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			username: process.env.DEFAULT_USERNAME,
			password: process.env.DEFAULT_PASSWORD,
			showModalPassword: false,
		};
		this.getLoginUrl = this.getLoginUrl.bind(this);
		this.onChangeHandler = this.onChangeHandler.bind(this);
		this.onSubmitHandler = this.onSubmitHandler.bind(this);
		this.onSelectLanguangeZhHantHandler = this.onSelectLanguangeHandler.bind(this, 'zh-Hant');
		this.onSelectLanguangeZhHansHandler = this.onSelectLanguangeHandler.bind(this, 'zh-Hans');
		this.onSelectLanguangeEnHandler = this.onSelectLanguangeHandler.bind(this, 'en');
		this.onToggleModalPasswordHandler = this.onToggleModalPasswordHandler.bind(this);
	}

	componentDidMount() {
		document.body.classList.add('background');
	}

	componentWillUnmount() {
		document.body.classList.remove('background');
	}

	componentDidUpdate(prevProps) {
		const loginInfo = this.props.loginInfo;
		const prevLoginInfo = prevProps.loginInfo;
		const changePasswordInfo = this.props.changePasswordInfo;
		const prevChangePasswordInfo = prevProps.changePasswordInfo;

		if (receiveApi(loginInfo, prevLoginInfo, cons.POST_LOGIN)) {
			if (loginInfo.data) {
				if (loginInfo.data.password_expired) {
					alert(loc.MSGPasswordExpired);
					this.setState({
						showModalPassword: true,
					});
					return;
				}
				const timestamp = Date.now();
				localStorage.setItem('user', JSON.stringify(Object.assign({}, loginInfo.data, { timestamp })));
				this.props.history.push('/');
			}
		} else if (loginInfo.type === cons.POST_LOGIN.FAILURE) {
			if (loginInfo.error) {
				if (!compareJson(loginInfo, prevLoginInfo)) {
					alert(loc[loginInfo.error.code]);
				}
			}
		}

		if (changePasswordInfo && changePasswordInfo !== prevChangePasswordInfo) {
			switch (changePasswordInfo.type) {
			case cons.CHANGE_PASSWORD.SUCCESS:
				this.setState({
					showModalPassword: false,
				});
				break;
			}
		}
	}

	getLoginUrl(props) {
		if (!props) {
			props = this.props;
		}
		return props.match.params.warehouse;
	}

	onChangeHandler(e) {
		let newState = {};
		newState[e.target.name] = e.target.value;
		this.setState(newState);
	}

	onSubmitHandler(e) {
		e.preventDefault();
		const { username, password } = this.state;
		let data = { username, password };
		const loginUrl = this.getLoginUrl();
		if (loginUrl) {
			data.login_url = loginUrl;
		}
		if (username !== '' && password !== '') {
			this.props.postLogin({ payload: data });
		}
	}

	onSelectLanguangeHandler(lang, e) {
		e.preventDefault();
		loc.changeLang(lang);
		this.props.setAppLanguage({ language: lang });
		localStorage.setItem('lang', lang);
	}

	onToggleModalPasswordHandler() {
		// Nothing to do
	}

	render() {
		const { loginInfo } = this.props;
		const { username, password, showModalPassword } = this.state;
		const isLoading = loginInfo.isFetching;
		return (
			<Fragment>
				<form className="uk-position-relative" onSubmit={ this.onSubmitHandler }>
					<div
						className="uk-width-1-1 uk-height-medium uk-background-cover uk-background-center-center"
						style={{ backgroundImage: 'url(/assets/images/Puyi-Optical-shop.jpg)' }}
						data-uk-height-viewport="offset-top: true; offset-bottom: 50"
					/>

					<LoadingOverlay text="" active={ isLoading }>
						<div className={ styles.form }>
							<p className="uk-text-center uk-h2 uk-text-bold">POS</p>

							<input
								className={ classnames('uk-input', styles.input) }
								type="text"
								name="username"
								autoComplete="off"
								placeholder={ loc.username }
								value={ username }
								onChange={ this.onChangeHandler }
							/>
							<input
								className={ classnames('uk-input', styles.input) }
								type="password"
								name="password"
								autoComplete="off"
								placeholder={ loc.password }
								value={ password }
								onChange={ this.onChangeHandler }
							/>

							<button
								className={ classnames('uk-button', 'uk-button-primary', 'uk-width-1-1', styles.button) }
								type="submit"
							>
								{ loc.login }
							</button>
						</div>

						<div className="uk-position-fixed uk-position-bottom-right">
							<nav className="uk-navbar-container uk-navbar-transparent" data-uk-navbar>
								<div className="uk-navbar-right uk-padding-small">
									<ul className={ classnames('uk-navbar-nav', styles.nav) }>
										<li>
											<a href="#" onClick={ this.onSelectLanguangeZhHantHandler }>繁中</a>
										</li>
										<li>
											<a href="#" onClick={ this.onSelectLanguangeZhHansHandler }>简中</a>
										</li>
										<li>
											<a href="#" onClick={ this.onSelectLanguangeEnHandler }>ENG</a>
										</li>
									</ul>
								</div>
							</nav>
						</div>
					</LoadingOverlay>

					<div className={ classnames('uk-text-small', 'uk-position-fixed', 'uk-position-bottom-center', styles.copyright) }>
						&copy; 2019-{ new Date().getFullYear() } PUYI GROUP LIMITED (HONG KONG)
					</div>
				</form>

				<ModalPassword
					isOpen={ showModalPassword }
					onToggle={ this.onToggleModalPasswordHandler }
				/>
			</Fragment>
		);
	}
}

export default connect(
	(state) => ({
		loginInfo: state.loginInfo,
		changePasswordInfo: state.changePasswordInfo,
		appLanguage: state.appLanguage,
	}),
	(dispatch) => ({
		postLogin: para => dispatch(postLogin(para)),
		setAppLanguage: para => dispatch(setAppLanguage(para)),
		getPayments: para => dispatch(getPayments(para)),
	})
)(LoginLayout);
  